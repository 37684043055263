@font-face {
  font-family: MatterWeb;
  src: url("./assets/fonts/Matter-Regular.woff2") format("woff2"),
  url("./assets/fonts/Matter-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  }
  
  @font-face {
  font-family: MatterWeb;
  src: url("./assets/fonts/Matter-SemiBold.woff2") format("woff2"),
  url("./assets/fonts/Matter-SemiBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
