@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    & h2 {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    & input {
        padding: 1em;
        width: 15em;
        height: 1em;
        margin-bottom: 1em;
        border: 1px solid #E4E8E3;
        border-radius: 5px;
    }
    &-button {
        padding: 1em;
        width: 5em;
        height: 1em;
        margin-top: 1em;
        border-radius: 25px;
        background-color: #f9b122;
        font-weight: bold;
        color: white;
        cursor: pointer;
        text-align: center;
    }
    &-radio {
        display: flex;
        align-items: center;
        justify-content: center;
        & input {
            width: 4em;
            margin: 0;
        }
        & p {
            margin: 0;
        }
    }
}
