.videocall {
    width: 100vw;
    &-participants {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw; 
        &-right {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100vw;
            &-scroll {
                // display: flex;
                // flex-direction: column;
                // justify-content: flex-start;
                // border: 1px solid black;
                // width: fit-content;
                height: 84%;
                position: fixed;
                top: 1em;
                // bottom: 10em;
                left: 80.5vw;
            } 
            &-scroll {
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-y: scroll;
            }
            &-scroll::-webkit-scrollbar {
                display: none;
            }
        }
    }
    &-images {
        display: flex;
        justify-content: center;
        width: 100vw;
        position: fixed;
        bottom: 2em;
    }
    &-images img {
        background-color: rgba($color: #8F8F8F, $alpha: 0.5);
        width: 1.5em;
        height: 1.5em;
        border-radius: 10px;
        padding: 15px 15px 15px 15px;
        cursor: pointer;
        color: white;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
    &-images-micoff {
        background-color: rgba($color: #DE4545, $alpha: 1) !important;
    }
    &-images-screenoff {
        background-color: rgba($color: #DE4545, $alpha: 1) !important;
    }
    &-images-shared {
        background-color: rgba($color: #A7D24E, $alpha: 1) !important;
    }
    &-images-speak {
        background-color: rgba($color: #A7D24E, $alpha: 1) !important;
    }
    &-images-exit {
        color: white;
        background-color: rgba($color: #8F8F8F, $alpha: 0.5);
        width: 1.5em;
        height: 1.5em;
        border-radius: 10px;
        padding: 15px 15px 15px 15px;
        cursor: pointer;
        color: white;
        margin-left: 0.3em;
        margin-right: 0.5em;
    }
    &-images-exit:hover {
        background-color: rgba($color: #DE4545, $alpha: 1);
    }
}
