.cameras-off {
    justify-content: center;
    width: 100vw;
    height: 84%;
    position: fixed;
    top: 2.5em;
    bottom: 0;
    left: 95vw;
    overflow-y: scroll;
    overflow-x: hidden;
    &-person {
        font-family: 'MatterWeb';
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        left: 86%;
        bottom: 30em;
        width: 4vw;
        height: 4vw;
        margin-bottom: 1em;
        border-radius: 15px;
        background-color: rgba($color: #D8D8D8, $alpha: 0.7);
    }
}
