.app {
  font-family: 'MatterWeb';  
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: #f7f9fc;
  & h1 {
    margin: 0;
    margin-top: 0.8em;
    color: black;
    align-self: center;
  }
}
