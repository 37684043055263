.participant {
    display: flex;
    justify-content: center;
    align-items: center;
    &-margin{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5em;
    }
    &-video {
        position: relative;
        width: 100%; 
    }
    &-video video {
        width: 100%;
        height: 100%;
    }
    &-overlay {
        font-family: 'MatterWeb';
        font-weight: 700;
        height: 0em;
        display: flex;
        align-items: center;
        position: relative;
        bottom: 2em;
        left: 2em;
        color: white;
        font-size: 0.9em;
        & p {
            background-color: rgba($color: #000000, $alpha: 0.5);
            border-radius: 5px;
            padding: 2px 5px;
        }
        &-speak {
            margin-right: 0.5em;
        }
        &-speaker {
            width: 4px;
            height: 5px;
            background-color: #88D048;
            margin-left: 0em;
            margin-right: 0.4em;
            border-radius: 5px;
        }
        &-speaker-on {
            width: 4px;
            height: 15px;
            background-color: #88D048;
            margin-left: 0em;
            margin-right: 0.4em;
            border-radius: 5px;
        }
        &-name {
            margin: 0;
            margin-left: 0.5em;
        }
        &-micoff {
            color: #DE4545;
            margin-left: 5px;
        }
        &-micon {
            width: 5px;
            margin-left: 5px;
        }

        //Right participants
        &-mini {
            font-family: 'MatterWeb';
            font-weight: 700;
            height: 0em;
            display: flex;
            align-items: center;
            position: relative;
            bottom: 2px;
            left: 8px;
            color: white;
            font-size: 0.9em;
        & p {
            background-color: rgba($color: #000000, $alpha: 0.5);
            border-radius: 5px;
            padding: 2px 5px;
        }
        &-speak {
            margin-right: 0.5em;
        }
        &-speaker {
            width: 4px;
            height: 5px;
            background-color: #88D048;
            margin-left: 0em;
            margin-right: 0.4em;
            border-radius: 5px;
        }
        &-speaker-on {
            width: 4px;
            height: 15px;
            background-color: #88D048;
            margin-left: 0em;
            margin-right: 0.4em;
            border-radius: 5px;
        }
        &-name {
            margin: 0;
            margin-left: 0.5em;
        }
        &-micoff {
            color: #DE4545;
            margin-left: 5px ; 
        }
        &-micon {
            width: 5px;
            margin-left: 5px;
            }
        }
    }
    &-hand {
        background-color: rgba($color: #000000, $alpha: 0.5);
        padding: 5px 4px 4px 10px;
        border-radius: 5px;
        position: absolute;
        top: 2vw;
        left: 2vw;
        z-index: 10;
        & img {
            background-color: red;
            width: 1.5vw;
            margin-left: 0em;
            margin-right: 0.4em;
        }
    }
}
.participants-right {
    // justify-content: center;
    // width: 100vw;
    // height: 80%;
    position: fixed;
    top: 1.5em;
    bottom: 0;
    // left: 92vw;
    overflow-y: scroll;
    overflow-x: hidden;
    &-hand {
        background-color: rgba($color: #000000, $alpha: 0.5);
        padding: 5px 4px 4px 10px;
        border-radius: 5px;
        position: absolute;
        top: 2vw;
        left: 2vw;
        z-index: 10;
        & img {
            background-color: red;
            width: 1.5vw;
            margin-left: 0em;
            margin-right: 0.4em;
        }
    }
}
